let config = null;

export default async () => {
  config =
    config === null
      ? await fetch(`${process.env.BASE_URL}config.json?t=${Date.now()}`).then(
          response => {
            return response.json();
          }
        )
      : config;
  return config;
};
